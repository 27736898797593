// Env
import { environment } from '../environments/environment';
// Angular
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';

import { provideHttpClient, withFetch, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// Store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';
// Components
import { AppComponent } from './app.component';
import { NotFoundComponent } from './404.component';
// Modules
import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from './core/core.module';
// Foster Modules
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { CookieConfig, CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { IconModule } from '@teamfoster/sdk/icon';
import { MenuNgrxModule } from '@teamfoster/sdk/menu-ngrx';
// Locale
import localeNl from './nl';
import { registerLocaleData } from '@angular/common';
// not used in production
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';

import { SocialMediaModule } from './social-media/social-media.module';

// Lottie animations
import { TextShapeComponent } from './standalone/text-shape/text-shape.component';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './custom-reuse-strategy';
import { provideCacheableAnimationLoader, provideLottieOptions } from 'ngx-lottie';

const cookieConfig: CookieConfig = {
  analyticsCode: 'GTM-P497MXDX',
  useTagManager: true,
  strippedContentPlaceholder: 'Deze inhoud wordt niet getoond omdat je geen marketing cookies hebt geaccepteerd',
  buttonAccept: 'test',
  buttonSettings: 'Instellingen',
  buttonAcceptAll: 'Alles accepteren',
  buttonClose: 'Sluiten',
  strippedContentButton: 'Cookie-instellingen wijzigen',
  types: {
    analytical: true,
    functional: true,
    marketing: true,
  },
  showSettings: true,
};

const componentParsers: Array<HookParserEntry> = [
  { component: TextShapeComponent },
  // ...
];

registerLocaleData(localeNl, 'nl');
@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    BrowserAnimationsModule,
    // NGRX
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    // Foster Modules
    DictionaryNgrxModule.forRoot({}),
    MenuNgrxModule.forRoot({}),
    SocialMediaModule,
    CookieNgrxModule.forRoot(cookieConfig),
    IconModule.forRoot({ spritesheetUrl: 'assets/dist/svg/symbol/svg/sprite.symbol.svg' }),
    DynamicHooksModule.forRoot({ globalParsers: componentParsers }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'nl' },
    {
      provide: RouteReuseStrategy,
      useClass: CustomReuseStrategy, // Use your custom RouteReuseStrategy
    },
    provideHttpClient(withInterceptorsFromDi(), withFetch()),
    provideClientHydration(),
    provideLottieOptions({ player: () => import('lottie-web') }),
    provideCacheableAnimationLoader(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
